
const HomeImageSection = () => {
    return (
        <div className="mt-10 -mb-16 m-0 relative">
            <div className="mx-auto max-w-none px-0">
                <img className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-680 lg:max-h-680 lg:w-auto lg:max-w-none" src="/homeSectionSvg.svg" alt="" />
            </div>
        </div>
    );
}

export default HomeImageSection;