
let user = localStorage.getItem("lims-user")
            ? JSON.parse(localStorage.getItem("lims-user"))
            : null;

let token = localStorage.getItem('lims-key') || null;

export const initialState = {
    user: null || user,
    token: null || token,
    loading: false,
    resMsg: null
};

export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case "ATTEMP_LOGIN":
            return {
                ...initialState,
                loading: true
            };
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                user: action.payload.user,
                token: action.payload.token,
                loading: false
            };
        case "LOGIN_FAIL":
            return {
                ...initialState,
                loading: false,
                resMsg: action.error
            };
        case "LOGOUT":
            return {
                ...initialState,
                user: null,
                token: null
            };
        default:
            return null;
    }
}

export default AuthReducer;