import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {useAuthState, useAuthDispatch} from '../../contexts/auth/AuthContext';
import login from '../../contexts/auth/AuthAction';
import { getSpinnerIcon } from '../../helper/SvgHelper';

const LoginForm = () => { 
    
    const history = useHistory();
    const dispatch = useAuthDispatch();
    const { loading, resMsg } = useAuthState();

    const [errorUsername, setErrorUsername] = useState(false);
    const [usernameValidateMsg, setUsernameValidateMsg] = useState("");
    const [errorPassword, setErrorPassword] = useState(false);
    const [passwordValidateMsg, setPasswordValidateMsg] = useState("");

    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const [requestPrompt, setRequestPrompt] = useState(false);

    const handleUsernameOnchange = (e) => {
        if(e === null || e === "") { 
            setUsernameValidateMsg("* Username is required");
            setErrorUsername(true);
            return false;
        }
        setUsernameValidateMsg("");
        setErrorUsername(false);
        setUsername(e);
    }

    const handlePasswordOnchange = (e) => {
        if(e === null || e === "") { 
            setPasswordValidateMsg("* Password is required");
            setErrorPassword(true);
            return false;
        }
        else if (e.length < 6) {
            setPasswordValidateMsg("* Password has to be at least 6 characters");
            setErrorPassword(true);
            return false;
        }
        setPassword(e);
        setPasswordValidateMsg("");
        setErrorPassword(false);
    }

    const handleFormSubmit = async () => {
        if(username === null || password === null) { return false; }
        else if(errorUsername === true || errorPassword === true) { return false; }
        else {
            try{
                const payload = { username, password};
                const res = await login(dispatch, payload)

                if(res && res.data && res.data.success) { 
                    history.push('/dashboard');
                }else{
                    setRequestPrompt(true);
                }    
            }
            catch(err){
                console.error(err);
            }
        }
    }
    
    useEffect(() => { 
        const token = localStorage.getItem('lims-key');
        if(!!token) {
            localStorage.removeItem('lims-key');
            localStorage.removeItem('lims-user'); 
        }
    }, []);

    return (
        <div className="bg-gray-50 flex flex-col justify-center py-6 px-8">
            <div className="mx-auto w-full max-w-md">
                <h2 className="mt-4 text-center text-3xl font-extrabold text-gray-900">
                    Sign in to your account
                </h2>
            </div>

            <div className="mt-8 mx-auto w-full max-w-md">
                <div className="bg-white py-8 shadow-md rounded-lg px-10">
                    <form onSubmit={ event => event.preventDefault() } className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Username
                            </label>
                            <div className="mt-1">
                                <input onChange = {event => handleUsernameOnchange(event.target.value)} type="text" className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"/>
                            </div>
                            <div className={errorUsername ? "" : "hidden"}>
                                <p className="text-red-500 text-xs italic">{ usernameValidateMsg }</p>
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input onChange = {event => handlePasswordOnchange(event.target.value)} type="password" className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"/>
                            </div>
                            <div className={errorPassword ? "" : "hidden"}>
                                <p className="text-red-500 text-xs italic">{ passwordValidateMsg }</p>
                            </div>
                        </div>


                        <div className="flex items-center">
                            <div className="text-sm">
                                {loading ? <div className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Forgot your password?
                                </div>
                                :
                                <Link to='/forgotpassword' className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Forgot your password?
                                </Link>}
                            </div>
                        </div>

                        <div>
                            <div className={requestPrompt && !loading ? "" : "hidden"}>
                                <p className="text-red-500 text-xs italic">{ resMsg }</p>
                            </div>
                            <button disabled={loading} onClick={ handleFormSubmit } type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                {loading ? getSpinnerIcon() : 'Sign in'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LoginForm;