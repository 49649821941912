import { useState, useEffect } from 'react';
import AccountTab from './AccountTab';
import CronTab from './CronTab';

const SettingsForm = () => {
    
    const [currentTab, setCurrentTab] = useState('Account');

    const settingsNavItem = [
        {label: 'Account', value: 'Account'},
        {label: 'Cron', value: 'Cron'}
    ];

    return (
        <div className="relative max-w-7xl mx-auto">
            <div className="px-2">
                {/* Setting Tabs */}
                {/* Should have map of tabs and conditional render on click */}
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8">
                        {settingsNavItem.map((item, index) =>(
                            <div onClick={() => setCurrentTab(item.value) } key={index} className={`${currentTab === item.value ? "text-purple-600 border-b-2 border-purple-500 " : "text-gray-900"} whitespace-nowrap py-4 px-1 border-b-2 font-medium text-md cursor-pointer`}>{item.label}</div>
                        ))}
                    </nav>
                </div>
                <div className='pt-8'>
                    {currentTab === 'Account' && <AccountTab />}
                    {currentTab === 'Cron' &&  <CronTab />}
                </div>
            </div>
        </div>
    )
}

export default SettingsForm;