import { getChevronRightIcon } from '../../helper/SvgHelper'

const HomeTitle = () => {
    return (
        <div className="px-6 pb-6 text-right items-center">
            <div className="h-3/4">
                <h1 className="tracking-tight font-extrabold text-white mt-6 text-5xl mr-14">
                    <div className="block">CAIQTest Pacific Ltd</div>
                    <div className="block text-indigo-400">Lims</div>
                </h1>
                {/* <a href="mailto:it_support@caiqtest.co.nz" className="inline-flex items-center text-white bg-gray-900 rounded-full p-1 pr-2 text-base hover:text-gray-200">
                    <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-indigo-500 rounded-full">Technical Support</span>
                    <span className="ml-4 text-sm">Please email IT support</span>
                    { getChevronRightIcon() }
                </a> */}
             </div>
         </div>
    );
}

export default HomeTitle;