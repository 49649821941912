import BatchTable from '../components/batch/BatchTable';
import SideMenu from '../components/dashboard/SideMenu';
import DashboardHeader from '../components/dashboard/DashboardHeader';

const Registration = () => {
    return (
        <div className="h-screen flex bg-gray-50">
            <SideMenu />
            <div className="flex-1 overflow-auto focus:outline-none">
                <DashboardHeader />
                <main className="flex-1 relative pb-8">
                    <div className="mt-8">
                        <BatchTable />
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Registration;