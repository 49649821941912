import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmailFormatValidator from '../../helper/ValidationHelper';
import api from '../../config/Api';
import { getSpinnerIcon, getSolidCheckCircleIcon, getSolidXIcon } from '../../helper/SvgHelper';

const ProfileTab = () => {

    const [firstName, setFirstName] = useState(null);
    const [firstNameErrorMsg, setFirstNameErrorMsg] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [lastNameErrorMsg, setLastNameErrorMsg] = useState(null);
    const [email, setEmail] = useState(null);
    const [emailErrorMsg, setEmailErrorMsg] = useState(null);
    const [initial, setInitial] = useState(null);
    const [branch, setBranch] = useState(null);
    const [position, setPosition] = useState(null);
    const [labGroup, setLabGroup] = useState(null);

    const [currentPassword, setCurrentPassword] = useState(null);
    const [currentPasswordErrorMsg, setCurrentPasswordErrorMsg] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState(null);
    const [confirmNewPassword, setConfirmNewPassword] = useState(null);
    const [confirmNewPasswordErrorMsg, setConfirmNewPasswordErrorMsg] = useState(null);

    const [updateStatus, setUpdateStatus] = useState(null);
    const [updateMsg, setUpdateMsg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPromtMsg, setShowPromtMsg] = useState(false);

    const firstNameInputValidation = (input) => {
        setFirstName(input);
        if(!input) {
            setFirstNameErrorMsg('* First name is required');
            return;
        }
        if(input.length >= 50) {
            setFirstNameErrorMsg('* First name should have less than 50 characters');
            return;
        }
        setFirstNameErrorMsg(null);
    }

    const lastNameInputValidation = (input) => {
        setLastName(input);
        if(!input) {
            setLastNameErrorMsg('* Last name is required');
            return;
        }
        if(input.length >= 50) {
            setLastNameErrorMsg('* Last name should have less than 50 characters');
            return;
        }
        setLastNameErrorMsg(null);
    }

    const emailInputValidation = (input) => {
        setEmail(input);
        if(!input) {
            setEmailErrorMsg('* Email is required');
            return;
        }
        if(!EmailFormatValidator(input)) {
            setEmailErrorMsg('* Email is in wrong format');
            return;
        }
        setEmailErrorMsg(null);
    }

    const currentPasswordValidation = (input) => {
        setCurrentPassword(input);
        if(input && input.length < 6) {
            setCurrentPasswordErrorMsg('* Current password must be at least 6 characters');
            return;
        }
        setCurrentPasswordErrorMsg(null);
    }

    const newPasswordValidation = (input) => {
        setNewPassword(input);
        if(input && input.length < 6) {
            setNewPasswordErrorMsg('* New Password must be at least 6 characters');
            return;
        }
        if(!currentPassword && input) {
            setCurrentPasswordErrorMsg('* Current password is required');
            return;
        }
        if(!input && currentPasswordErrorMsg === '* Current password is required') {
            setCurrentPasswordErrorMsg(null);
        }
        setNewPasswordErrorMsg(null);
    }

    const confirmNewPasswordValidation = (input) => {
        setConfirmNewPassword(input);
        if(input && input.length < 6) {
            setConfirmNewPasswordErrorMsg('* Confirm Password must be at least 6 characters');
            return;
        }
        if(!newPassword && input) {
            setNewPasswordErrorMsg('* New password is required');
            return;
        }
        if(input && input !== newPassword) {
            setConfirmNewPasswordErrorMsg('* Password does not match');
            return;
        }
        if(!input && newPasswordErrorMsg === '* New password is required') {
            setNewPasswordErrorMsg(null);
        }
        setConfirmNewPasswordErrorMsg(null);
    }

    const updateUserProfile = async () => {
        if((currentPassword && newPassword && confirmNewPassword) || (!currentPassword && !newPassword && !confirmNewPassword)) {
            if(!firstNameErrorMsg && !lastNameErrorMsg && !emailErrorMsg && !currentPasswordErrorMsg && !newPasswordErrorMsg && !confirmNewPasswordErrorMsg) {
                setShowPromtMsg(false);
                setLoading(true);
                await api.put('/lims/update-profile', { firstName, lastName, email, currentPassword, newPassword, confirmNewPassword })
                .then(res => {
                    if(res.data.success) {
                        console.log(res)
                        let limsUser = JSON.parse(localStorage.getItem("lims-user"));
                        limsUser.name = res.data.data.firstName + " " + res.data.data.lastName;
                        localStorage.setItem("lims-user", JSON.stringify(limsUser));

                        if(res.data.data.token) {
                            localStorage.setItem("lims-key", res.data.data.token);
                        }

                        setUpdateStatus(res.data.success);
                        setUpdateMsg(res.data.message);
                    }
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        setUpdateMsg('Missing of required field(s).');
                    }
                    else if (err.response.status === 409) {
                        setUpdateMsg('Email has been taken.');
                    }
                    else if (err.response.status === 403) {
                        setUpdateMsg('Incorrect current password.');
                    }
                    else {
                        setUpdateMsg('Something went wrong, please contact IT Support.');
                    }
                    setUpdateStatus(false);
                });
                setLoading(false);
                setShowPromtMsg(true);
            }
        }
        else {
            currentPasswordValidation(currentPassword);
            newPasswordValidation(newPassword);
            confirmNewPasswordValidation(confirmNewPassword);
        }
    }


    const getUserProfile = async () => {
        setShowPromtMsg(false);
        await api.get('/lims/profile')
        .then(res => {
            if(res.data.success) { 
                setFirstName(res.data.data.firstName);
                setLastName(res.data.data.lastName);
                setInitial(res.data.data.initial);
                setEmail(res.data.data.email);
                setBranch(res.data.data.branch);
                setPosition(res.data.data.position);
                setLabGroup(res.data.data.labGroup);
            };
        })
        .catch(err => {
            setUpdateMsg('Something went wrong, please contact IT Support.');
            setUpdateStatus(false);
            setShowPromtMsg(true);
        });
    }

    useEffect(() => {
        getUserProfile();
    }, []);

    return (
        <form onSubmit={ event => event.preventDefault() }>
            <div className="shadow rounded-md">
                <div className="p-6 py-5 bg-white">

                    {showPromtMsg && <div className={`${updateStatus ? 'bg-green-50' : 'bg-red-50'} rounded-md p-4 mb-4`}>
                        <div className="flex">
                            <div className="flex-shrink-0">
                                { updateStatus ? getSolidCheckCircleIcon() : getSolidXIcon() }
                            </div>
                            <div className="ml-3">
                                <p className={`${updateStatus ? 'text-green-800' : 'text-red-800'} text-sm font-medium`}>{updateMsg}</p>
                            </div>
                        </div>
                    </div>}


                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6">
                            <p>Basic Info:</p>
                        </div>

                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                                First name
                            </label>
                            <input
                                type="text"
                                className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                value={firstName || ''}
                                onChange={e => { firstNameInputValidation(e.target.value); }}
                            />
                            {firstNameErrorMsg && <p className="text-red-500 text-xs italic">{firstNameErrorMsg}</p>}
                        </div>

                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                                Last name
                            </label>
                            <input
                                type="text"
                                className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                value={lastName || ''}
                                onChange={e => { lastNameInputValidation(e.target.value); }}
                            />
                            {lastNameErrorMsg && <p className="text-red-500 text-xs italic">{lastNameErrorMsg}</p>}
                        </div>
                        
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                                Initial
                            </label>
                            <input
                                type="text"
                                className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                value={initial || ''}
                                disabled={true}
                            />
                        </div>

                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <input
                                type="text"
                                className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                value={email || ''}
                                onChange={e => { emailInputValidation(e.target.value); }}
                            />
                            {emailErrorMsg && <p className="text-red-500 text-xs italic">{emailErrorMsg}</p>}
                        </div>
                        

                        <div className="col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Branch
                            </label>
                            <input
                                type="text"
                                className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                value={branch || ''}
                                disabled={true}
                            />
                        </div>

                        <div className="col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Position
                            </label>
                            <input
                                type="text"
                                className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                value={position || ''}
                                disabled={true}
                            />
                        </div>

                        <div className="col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Lab Group
                            </label>
                            <input
                                type="text"
                                className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                value={labGroup || ''}
                                disabled={true}
                            />
                        </div>

                        <div className="col-span-6 mt-6">
                            <p>Credential Info:</p>
                        </div>

                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                                Current Password
                            </label>
                            <input
                                type="password"
                                className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                value={currentPassword || ''}
                                onChange={e => { currentPasswordValidation(e.target.value); }}
                            />
                            {currentPasswordErrorMsg && <p className="text-red-500 text-xs italic">{currentPasswordErrorMsg}</p>}
                        </div>

                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                                New Password
                            </label>
                            <input
                                type="password"
                                className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                value={newPassword || ''}
                                onChange={e => { newPasswordValidation(e.target.value); }}
                            />
                            {newPasswordErrorMsg && <p className="text-red-500 text-xs italic">{newPasswordErrorMsg}</p>}
                        </div>

                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                                Confirm Password
                            </label>
                            <input
                                type="password"
                                className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                value={confirmNewPassword || ''}
                                onChange={e => { confirmNewPasswordValidation(e.target.value); }}
                            />
                            {confirmNewPasswordErrorMsg && <p className="text-red-500 text-xs italic">{confirmNewPasswordErrorMsg}</p>}
                        </div>
                    </div>
                </div>
                <div className="px-6 py-3 bg-gray-100 shadow-md flex justify-between">
                    <Link to='/dashboard'
                        className="bg-purple-600 rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-purple-700 focus:outline-none"
                    >
                        Cancel
                    </Link>
                    <button
                        type="submit"
                        className="bg-indigo-600 rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none"
                        onClick={() => { updateUserProfile(); }}
                        disabled={loading}
                    >
                        { loading ? <div className="px-1.5">{getSpinnerIcon()}</div> : "Save" }
                    </button>
                </div>
            </div>
        </form>

    )
}

export default ProfileTab;