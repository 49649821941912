import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import ForgotPassword from '../pages/ForgotPassword';
import Settings from '../pages/Settings';
import Profile from '../pages/Profile';
import Registration from '../pages/Registration';
import Batch from '../pages/Batch';
import SampleSubmission from '../pages/SampleSubmission';

const routes = [
    {
        path: '/dashboard/registration',
        component: Registration,
        isPrivate: true
    },
    {
        path: '/dashboard/batch',
        component: Batch,
        isPrivate: true
    },
    {
        path: '/dashboard/samplesubmission',
        component: SampleSubmission,
        isPrivate: true
    },
    {
        path: '/dashboard/settings',
        component: Settings,
        isPrivate: true
    },
    {
        path: '/dashboard/profile',
        component: Profile,
        isPrivate: true
    },
    {
        path: '/dashboard',
        component: Dashboard,
        isPrivate: true
    },
    {
        path: '/forgotpassword',
        component: ForgotPassword,
        isPrivate: false
    },
    {
        path: '/login',
        component: Home,
        isPrivate: false
    },
    {
        path: '/',
        component: Home,
        isPrivate: false
    }
]

export default routes;