import api from '../../config/Api';

export const login = async (dispatch, payload) => {
    const {username, password} = payload;
    dispatch({ type: 'ATTEMP_LOGIN' });
    const response = await api.post('/lims/login', {username, password})
    .then(res => {
        if(res.data.success === true) {
            const token = res.data.data.token;
            localStorage.setItem("lims-key", token);
            const user = {'name': res.data.data.name, 'accountType': res.data.data.accountType, 'privileges': res.data.data.privileges};
            localStorage.setItem("lims-user", JSON.stringify(user));
            dispatch({ type: 'LOGIN_SUCCESS', payload: { token ,user}});
            return res;
        }
    })
    .catch(e => {
        if (e.response.status === 403) {
            dispatch({ type: 'LOGIN_FAIL', error:'* Inactive user account' });
        }
        else if (e.response.status === 400) {
            dispatch({ type: 'LOGIN_FAIL', error:'* Username and password do not match' });
        }
        else { dispatch({ type: 'LOGIN_FAIL', error: e.message }); }
    });

    return response;
}

export const logout = async(dispatch) => {
    await api.post('/lims/logout')
    .catch(e => {
        throw e;
    });
    dispatch({ type: 'LOGOUT'});
}

export default login;