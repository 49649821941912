import { useState, useEffect } from 'react';
import api from '../../config/Api';
  
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const CronTab = () => {
    const [toggleList, setToggleList] = useState([]);

    const getCronToggleList = async () => {

        await api.get('/lims/cron-toggles')
        .then(res => {
            if(res.data.success) { 
                setToggleList(res.data.data);
            };
        })
        .catch(err => {
        });
    }

    const updateCronToggleValue = async (toggleId, isActiveAltered) => {
        await api.put('/lims/update-toggle',{toggleId, isActiveAltered })
        .then(res => {
            if(res.data.success) { 
                setToggleList(res.data.data);
            };
        })
        .catch(err => {
        });
    }

    useEffect(() => { 
        getCronToggleList();
    }, []);

    return (
        <div>
            <div
                className={classNames(
                'rounded-tl-md rounded-tr-md',
                'group relative flex flex-col border border-gray-200 p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6',
                )}
            >
                <span className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                    <span className="font-bold text-gray-900">
                        Name
                    </span>
                </span>
                <span className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                    <span className="font-bold text-gray-900">
                        TestMode
                    </span>
                </span>
                <span className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                    <span className="font-bold text-gray-900">
                        Active
                    </span>
                </span>
            </div>
            <div className="relative -space-y-px rounded-md bg-white max-h-630 overflow-y-auto">
                {toggleList.map((toggle, i) => (
                    <div
                        key={toggle.ToggleId}
                        className={classNames(
                        i === toggleList.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                        'group relative flex flex-col border border-gray-200 p-4 focus:outline-none data-[checked]:z-10 data-[checked]:border-indigo-200 data-[checked]:bg-indigo-50 md:grid md:grid-cols-3 md:pl-4 md:pr-6',
                        )}
                    >
                        <span className="flex items-center text-sm">
                            <span
                                aria-hidden="true"
                                className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-300 bg-white group-data-[checked]:border-transparent group-data-[checked]:bg-indigo-600 group-data-[focus]:ring-2 group-data-[focus]:ring-indigo-600 group-data-[focus]:ring-offset-2"
                            >
                                <span className="h-1.5 w-1.5 rounded-full bg-black" />
                            </span>
                            <span className="ml-3 font-medium text-gray-900 group-data-[checked]:text-indigo-900">{toggle.ToggleName}</span>
                        </span>
                        <span className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                            <input checked={toggle.TestMode == 'T' ? true : false} type="checkbox" onChange={() => updateCronToggleValue(toggle.ToggleId, false)} className="h-4 w-4 border-gray-300 rounded cursor-pointer" />
                        </span>
                        <span className="ml-6 pl-1 text-sm text-gray-500 group-data-[checked]:text-indigo-700 md:ml-0 md:pl-0 md:text-center">
                            <input checked={toggle.Active == 'T' ? true : false} type="checkbox" onChange={() => updateCronToggleValue(toggle.ToggleId, true)} className="h-4 w-4 border-gray-300 rounded cursor-pointer" />
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CronTab;