import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmailFormatValidator from '../../helper/ValidationHelper'
import api from '../../config/Api';
import { getSpinnerIcon } from '../../helper/SvgHelper';

const ForgotPasswordForm = () => {

    const [username, setUsername] = useState(null);
    const [usernameValidateMsg, setUsernameValidateMsg] = useState("");
    const [errorUsername, setErrorUsername] = useState(false);

    const [email, setEmail] = useState(null);
    const [emailValidateMsg, setEmailValidateMsg] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);

    const [loading, setLoading] = useState(false);

    const [requestSuccess, setRequestSuccess] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [promptMsg, setPromptMsg] = useState(null);

    const handleUsernameOnchange = (e) => {
        if(e === null || e === "") { 
            setUsernameValidateMsg("* Username is required");
            setErrorUsername(true);
            return false;
        }
        setUsername(e);
        setUsernameValidateMsg("");
        setErrorUsername(false);
    }

    const handleEmailOnchange = (e) => {
        if(e === null || e === "") { 
            setEmailValidateMsg("* Email is required");
            setErrorEmail(true);
            return false;
        }
        else if (!EmailFormatValidator(e)) {
            setEmailValidateMsg("* Email is not in the correct format");
            setErrorEmail(true);
            return false;
        }
        setEmail(e);
        setEmailValidateMsg("");
        setErrorEmail(false);
    }

    const handleFormSubmit = async () => {
        if(username === null || email === null) { return false; }
        else if(errorUsername === true || errorEmail === true) { return false; }
        else {
            try{
                setLoading(true);
                await api.put('/lims/reset-password', {username, email})
                .then(res => {
                    if(res.data.success) { 
                        setPromptMsg(res.data.message);
                        setRequestSuccess(true);
                    }
                })
                .catch(e => {
                    setPromptMsg('Something went wrong, please contact IT Support.');
                    setRequestSuccess(false);
                });
                setLoading(false);
                setShowPrompt(true);
            }
            catch(err){
                console.error(err);
            }
        }
    }

    useEffect(() => { 
        const token = localStorage.getItem('lims-key');
        if(!!token) { 
            localStorage.removeItem('lims-key');
            localStorage.removeItem('lims-user'); 
        }
    }, []);

    return(
        <div className="bg-gray-50 flex flex-col justify-center py-6 px-8">
            <div className="mx-auto w-full max-w-md">
                <h2 className="mt-4 text-center text-3xl font-extrabold text-gray-900">
                    Reset your password
                </h2>
            </div>

            <div className="mt-8 mx-auto w-full max-w-md">
                <div className="bg-white py-8 shadow-md rounded-lg px-10">
                    <form onSubmit={ event => event.preventDefault() } className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Username
                            </label>
                            <div className="mt-1">
                                <input onChange = {event => handleUsernameOnchange(event.target.value)} type="text" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                            </div>
                            <div className={errorUsername ? "" : "hidden"}>
                                <p className="text-red-500 text-xs italic"> { usernameValidateMsg }</p>
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input onChange = {event => handleEmailOnchange(event.target.value)} type="text" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                            </div>
                            <div className={errorEmail ? "" : "hidden"}>
                                <p className="text-red-500 text-xs italic">{ emailValidateMsg }</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div className="text-sm">
                                {loading ? <div className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Sign in to your account
                                </div>
                                :
                                <Link to='/login' className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Sign in to your account
                                </Link>}
                            </div>
                        </div>

                        <div>
                            <div className={showPrompt && !loading ? "" : "hidden"}>
                                <p className={`${requestSuccess ? 'text-green-500' : 'text-red-500'} text-xs italic`}>{'* ' + promptMsg }</p>
                            </div>
                            <button disabled={loading} onClick={ handleFormSubmit } type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                {loading ? getSpinnerIcon() : 'Send email'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordForm;