import React, { useState, useEffect, useRef, useMemo} from 'react';
import NzDateStringToIsoDate, { IsoDateTimeToIsoDate, DayDifference } from '../../helper/DateHelper';
import getDownloadIcon, {getMagnifierIcon, getFilterIcon, getSortingIcon, getDoubleArrowLeftIcon, getDoubleArrowRightIcon, getSingleArrowLeftIcon, getSingleArrowRightIcon, getRefreshIcon, getCalenderIcon, getSpinnerIcon} from '../../helper/SvgHelper';
import useWindowDimensions from '../../helper/WindowDimensionHelper';
import api from '../../config/Api';

const SummaryInfo = () => {
    const [dashboardInfo, setDashboardInfo] = useState(null);
    const[loading, setLoading] = useState(false);

    const getDashboardInfo = async () => {
        setLoading(true);
        await api.get('/lims/dashboard-info')
                .then(res => {
                    if(res.data.success) { 
                        setDashboardInfo(res.data.data);
                    }else {
                        setDashboardInfo(null);
                    };
                })
                .catch(err => {
                    console.log(err)
                });
        setLoading(false);
    }

    const determineColumnHeader = (listName, colNum) => {
        if(listName =="Batch Await Approval") {
            if(colNum == 1) {
                return "Batch ID"
            }
            else if(colNum == 2) {
                return "Description"
            }
            else if(colNum == 3) {
                return "Batch Date"
            }
        }
        else if(listName =="Reg Completed") {
            if(colNum == 1) {
                return "Reg No."
            }
            else if(colNum == 2) {
                return "Description"
            }
            else if(colNum == 3) {
                return "Due Date"
            }
        }
        else if(listName =="Recent Reported") {
            if(colNum == 1) {
                return "Reg No."
            }
            else if(colNum == 2) {
                return "Description"
            }
            else if(colNum == 3) {
                return "Report Date"
            }
        }
        else if(listName =="Batch Await Complete") {
            if(colNum == 1) {
                return "Batch ID"
            }
            else if(colNum == 2) {
                return "Description"
            }
            else if(colNum == 3) {
                return "Batch Date"
            }
        }
        else if(listName =="Submissions Await Acceptance") {
            if(colNum == 1) {
                return "Client"
            }
            else if(colNum == 2) {
                return "Sample Name"
            }
            else if(colNum == 3) {
                return "Submission Date"
            }
        }
    }

    useEffect(() => {
        getDashboardInfo();
    }, []);

    return (

        <div className="hidden sm:block">
            <div className="w-11/12 mx-auto">
                <div className="flex flex-col mt-2">
                    {dashboardInfo && 
                    <div role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 mt-4">
                        {Object.keys(dashboardInfo).map((info, index) => (
                            <div key={index} className="overflow-hidden rounded-xl border border-gray-200">
                                <div className="items-center gap-x-4 border-b border-gray-900/5 bg-gray-100 p-6">
                                    <div className="flex">
                                        <div className="text-md font-medium leading-6 text-gray-900">{info}</div>
                                        <div 
                                            className="relative ml-auto cursor-pointer"
                                            onClick={()=>getDashboardInfo()}
                                        >{ loading ? getSpinnerIcon() : getRefreshIcon() }</div>
                                    </div>
                                    <div className="flex text-sm text-gray-600 justify-between mt-6 -mb-4">
                                        <div>{determineColumnHeader(info, 1)}</div>
                                        <div>{determineColumnHeader(info, 2)}</div>
                                        <div>{determineColumnHeader(info, 3)}</div>
                                    </div>
                                </div>
                                <div className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6 max-h-680 overflow-y-auto">
                                    {dashboardInfo[info].map((data, i) => (
                                        info == "Batch Await Approval" ?
                                        <div key={i} className="flex justify-between gap-x-4 py-3">
                                            <div className="text-gray-500 whitespace-nowrap">{data.BATCHID}</div>
                                            <div className="font-medium text-gray-900 max-w-265 whitespace-nowrap overflow-x-hidden">{data.DESCRIPTION}</div>
                                            <div className="font-medium text-gray-900 whitespace-nowrap">{data.BATCHDATE}</div>
                                        </div>
                                        :
                                        info == "Reg Completed" ?
                                        <div key={i} className="flex justify-between gap-x-4 py-3">
                                            <div className="text-gray-500 whitespace-nowrap">{data.URegRef}</div>
                                            <div className="font-medium text-gray-900 max-w-265 whitespace-nowrap overflow-x-hidden">{data.JobDescrip}</div>
                                            <div className="font-medium text-gray-900 whitespace-nowrap">{data.DueDate}</div>
                                        </div>
                                        :
                                        info == "Recent Reported" ?
                                        <div key={i} className="flex justify-between gap-x-4 py-3">
                                            <div className="text-gray-500 whitespace-nowrap">{data.URegRef}</div>
                                            <div className="font-medium text-gray-900 max-w-265 whitespace-nowrap overflow-x-hidden">{data.JobDescrip}</div>
                                            <div className="font-medium text-gray-900 whitespace-nowrap">{data.EMAILED_DATE}</div>
                                        </div>
                                        :
                                        info == "Batch Await Complete" ?
                                        <div key={i} className="flex justify-between gap-x-4 py-3">
                                            <div className="text-gray-500 whitespace-nowrap">{data.BATCHID}</div>
                                            <div className="font-medium text-gray-900 max-w-265 whitespace-nowrap overflow-x-hidden">{data.DESCRIPTION}</div>
                                            <div className="font-medium text-gray-900 whitespace-nowrap">{data.BATCHDATE}</div>
                                        </div>
                                        :
                                        info == "Submissions Await Acceptance" ?
                                        <div key={i} className="flex justify-between gap-x-4 py-3">
                                            <div className="text-gray-500 whitespace-nowrap">{data.Client}</div>
                                            <div className="font-medium text-gray-900 max-w-265 whitespace-nowrap overflow-x-hidden">{data.SampleName}</div>
                                            <div className="font-medium text-gray-900 whitespace-nowrap ">{data.CreatedAt}</div>
                                        </div>
                                        :
                                        <div key={i} className="flex justify-between gap-x-4 py-3">
                                            <div className="text-gray-500"></div>
                                            <div className="font-medium text-gray-900"></div>
                                            <div className="font-medium text-gray-900"></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SummaryInfo;