

const HomeFooter = () => {
    return (
        <footer className="position: absolute footer bottom-0 bg-gray-900 w-full text-right pb-2 pr-4">
            <div className="block text-gray-500 text-xs">
                &copy; { new Date().getFullYear() } - CAIQTest Pacific Limited
            </div>
        </footer>
    );
}

export default HomeFooter;
