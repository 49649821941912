import HomeFooter from '../components/home/HomeFooter';
import HomeTermsSection from '../components/home/HomeTermsSection';
import HomeMainNav from '../components/home/HomeMainNav';
import HomeTitle from '../components/home/HomeTitle';
import HomeImageSection from '../components/home/HomeImageSection';
import ForgotPasswordForm from '../components/auth/ForgotPasswordForm';

const ForgotPassword = () => {

    return (
        <div className="bg-gray-900 min-h-screen">
            <div className="mx-auto max-w-7xl pt-12 pb-5">
                <HomeMainNav />
                <main className="mt-14">
                    <div className="grid grid-cols-12 gap-8">
                        <div className="mt-0 col-span-6">
                            <HomeTitle />
                            <div className="bg-white max-w-md w-full mx-auto rounded-lg overflow-hidden">
                                <ForgotPasswordForm />
                                <HomeTermsSection />
                            </div>
                        </div>
                        <HomeImageSection />
                    </div>
                </main>
            </div>
            <HomeFooter />
        </div>
    );
}

export default ForgotPassword;