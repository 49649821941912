import React, { useState, useEffect, useRef, useMemo} from 'react';
import {getSortingIcon, getRefreshIcon, getSpinnerIcon, getChevronUpIcon, getSolidCheckCircleIcon, getSolidXIcon} from '../../helper/SvgHelper';
import useWindowDimensions from '../../helper/WindowDimensionHelper';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from "react-table";
import { matchSorter } from 'match-sorter'
import api from '../../config/Api';

function DefaultColumnFilter({column: { filterValue, preFilteredRows, setFilter },}) {
    //const count = preFilteredRows.length
    return (<></>)
}

const SortingIcon = ({columnName, currentColumnName, sortAsc}) => {
    return (
        <>
            {getSortingIcon(columnName, currentColumnName, sortAsc)}
        </>
    )
};

function GlobalFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter, getRegistrationList, setRegistrationList}) {

    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce(value => { setGlobalFilter(value || undefined) }, 200);
    
    return (
        <div className="ml-4">
            <input 
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }} 
                type="search"
                placeholder="Filter"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm  focus:outline-none text-sm"
            />
        </div>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
} 
fuzzyTextFilterFn.autoRemove = val => !val;

function ReactTableFooter ({setShowResult, setSelectedRow, enteredTestResults, setEnteredTestResults,canApprove,selectedApprovalTests,setSelectedApprovalTests,setIsUnderApproval,approvalType,setApprovalType}) {
    
    const [responseStatus, setResponsStatus] = useState(null);
    const [responseMsg, setResponseMsg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPromtMsg, setShowPromtMsg] = useState(false);

    const buttonGroupOptionRef = useRef(null);
    const [showButtonGroupOptions, setShowButtonGroupOptions] = useState(false);
    const [showButtonGroup, setShowButtonGroup] = useState(true);
    const [showApprovalButton, setShowApprovalButton] = useState(false);

    const customOptions = ['Procedures', 'Results'];

    const selectButtonGroupOption = (option) => {
        setApprovalType(option);
        setShowButtonGroupOptions(false);
        setShowButtonGroup(false);
        setShowApprovalButton(true);
        setIsUnderApproval(true);
    }

    const cancelProcedureApproval = () => {
        setApprovalType(null);
        setShowApprovalButton(false);
        setShowButtonGroup(true);
        setSelectedApprovalTests([]);
        setIsUnderApproval(false);
    }

    const closeRegResultTable = () => {
        setEnteredTestResults([]);
        setSelectedApprovalTests([]);
        setSelectedRow(null);
        setShowResult(false);
    }

    const updateEnteredTestResults = async () => {
        if(enteredTestResults.length === 0) { return; }
        setShowPromtMsg(false);
        setLoading(true);
        
        await api.put('/lims/enter-registration-results', { resultData: enteredTestResults })
        .then(res => {
            if(res.data.success) { 
                setResponseMsg(res.data.message);
                setResponsStatus(true);
            };
        })
        .catch(err => {
            if (err && err.response && err.response.data && err.response.data.message) {
                setResponseMsg(err.response.data.message.toString());
            }
            else {
                setResponseMsg('Something went wrong, please contact IT Support.');
            }
            setResponsStatus(false);
        })
        .finally(() => {
            setEnteredTestResults([]);
        });
        setLoading(false);
        setShowPromtMsg(true);
    }
    
    const approveRegTests = async () => {
        if(!approvalType || selectedApprovalTests.length===0) { return; }
        setShowPromtMsg(false);
        setLoading(true);
        
        await api.put('/lims/approve-registration-results', { resultIds:selectedApprovalTests})
        .then(res => {
            if(res.data.success) { 
                setResponseMsg(res.data.message);
                setResponsStatus(true);
            };
        })
        .catch(err => {
            if (err && err.response && err.response.data && err.response.data.message) {
                setResponseMsg(err.response.data.message.toString());
            }
            else {
                setResponseMsg('Something went wrong, please contact IT Support.');
            }
            setResponsStatus(false);
        })
        .finally(() => {
            setApprovalType(null);
            setShowButtonGroupOptions(false);
            setShowButtonGroup(true);
            setShowApprovalButton(false);
            setIsUnderApproval(false);
            setSelectedApprovalTests([]);
        });
        setLoading(false);
        setShowPromtMsg(true);
    }
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if(buttonGroupOptionRef.current && !buttonGroupOptionRef.current.contains(event.target)) {
                setShowButtonGroupOptions(false);
            }
        }
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    
    return (
        <nav className="px-4 py-3 flex items-center justify-between border-t border-gray-200 bg-gray-100 rounded-br-lg rounded-bl-lg">
            <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <button onClick={()=>{closeRegResultTable()}} type="button" className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    Close
                </button>      
            </div>

            {showPromtMsg && <div className={`${responseStatus ? 'bg-green-50' : 'bg-red-50'} rounded-md p-4`}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        { responseStatus ? getSolidCheckCircleIcon() : getSolidXIcon() }
                    </div>
                    <div className="ml-3">
                        <p className={`${responseStatus ? 'text-green-800' : 'text-red-800'} text-sm font-medium`}>{responseMsg}</p>
                    </div>
                </div>
            </div>}

            <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {/* Approval Button Groups */}
                {canApprove > 0 && showButtonGroup && 
                <div ref={ buttonGroupOptionRef } className="relative inline-block text-left mx-5">
                    <div className="inline-flex rounded-md shadow-sm">
                        <button
                            type="button"
                            className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-800 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
                            onClick={(e) => {setShowButtonGroupOptions(!showButtonGroupOptions); e.stopPropagation(); }}
                        >
                            Approve
                        </button>
                        <div className="relative -ml-px block">
                            <button 
                                className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
                                onClick={(e) => {setShowButtonGroupOptions(!showButtonGroupOptions); e.stopPropagation(); }}
                                >
                                <span className="sr-only">Open options</span>
                                <div>{getChevronUpIcon()}</div>
                            </button>
                        </div>
                    </div>
                
                    {showButtonGroupOptions && 
                        <div className="absolute top-0 right-0 left-0 -mt-20 p-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            {customOptions.map((option, i) => (
                            <div className="py-1 hover:bg-blue-100 rounded-md" key={i}>
                                <div 
                                    className={'text-md text-gray-900 cursor-pointer px-2 py-1'}
                                    onClick={()=>{selectButtonGroupOption(option)}}
                                >
                                    {option}
                                </div>
                            </div>))}
                        </div>}
                    </div>}
                    {showApprovalButton && 
                    <div className="relative inline-block text-left mx-5">
                        <div className="inline-flex rounded-md shadow-sm">
                            <button
                                type="button"
                                className="items-center rounded-md mx-1 px-3 py-2 text-sm  text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={(e) => {approveRegTests(); }}
                                disabled={approvalType==='Results' && selectedApprovalTests.length===0}
                            >
                                {'Approve ' + approvalType}
                            </button>
                            <button 
                                className="items-center rounded-md mx-1 px-3 py-2 text-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                                onClick={(e) => {cancelProcedureApproval();}}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>}
                
                <button 
                    onClick={()=>{ updateEnteredTestResults(); }} 
                    type="submit" 
                    className={`inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white ${approvalType !=null ? 'bg-gray-300 hover:bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    disabled={loading || approvalType != null}
                >
                    { loading ? <div className="px-1.5">{getSpinnerIcon()}</div> : "Save" }
                </button>      
            </div>
        </nav>
    )
}

const RefreshIcon = ({getRegResultList, selectedRegTID, setSortColumnName, setSortAsc, setEnteredTestResults}) => {

    const [loading, setLoading] = useState(false);

    const refreshRegistrationList = async () => {
        setLoading(true);
        setSortColumnName(null);
        setSortAsc(null);
        setEnteredTestResults([]);
        await getRegResultList(selectedRegTID);
        setLoading(false);
    }

    return (
        <div className="h-6 w-6">
            <button className="ml-6 py-1 text-center focus:outline-none" onClick={()=>refreshRegistrationList()}>
                {loading ? getSpinnerIcon() : getRefreshIcon("#6b7280")}
            </button>
        </div>
    );
}

const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column: { id, Header },
    updateTableData, // This is a custom function that we supplied to our table instance
    procedureApprovals,
    selectedRow,
    selectedApprovalTests,
    isUnderApproval
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue)
    const [showTooltip, setShowTooltip] = useState(false);
    const inputFieldRef = useRef(null);
    const { height, width } = useWindowDimensions();
    
    let batchIdProp = 'Sample' + Header.toString() + 'BATCHID';
    let lowerProp = 'Sample' + Header.toString() + 'LOWER';
    let upperProp = 'Sample' + Header.toString() + 'UPPER';
    let approvedByProp = 'Sample' + Header.toString() + 'APPROVEDBY';
    let sigFigPrec = original['SigFig'] ? 'SigFig: '+original['SigFig'] : 'Prec: '+ original['Prec'];
    let resultIdProp = id.replace('WEBRESULT','RESULT_ID');

    const buildTooltipText = () => {
        if(!isNaN(Header)) { 
            return  [<div>BatchId: {original[batchIdProp]}</div>,<div>L: {original[lowerProp]} &nbsp;/&nbsp; U: {original[upperProp]}</div>,<div>{sigFigPrec}</div>,<div>ApprovedBy: {original[approvedByProp]}</div>];
        }
    }

    const buildClassName = () => {
        let defualtClassName = 'max-w-80 text-center';
        if(!original[resultIdProp]) { return defualtClassName = defualtClassName + ' bg-gray-100'; }
        if(isUnderApproval && selectedApprovalTests.includes(original[resultIdProp])) { defualtClassName = defualtClassName + ' border-2 border-blue-300'; }
        if((original[approvedByProp] || original['PrAPPROVEDBY']) && original[resultIdProp]) {
            return 'text-green-800 bg-green-100 rounded-md '+ defualtClassName;
        }
        if(value && !isNaN(value)) {
            if( (original[upperProp] && !isNaN(original[upperProp]) && Number(value) > Number(original[upperProp])) ||
            (original[lowerProp] && !isNaN(original[lowerProp]) && Number(value) < Number(original[lowerProp]))) {
                return 'text-red-800 bg-red-100 rounded-md '+ defualtClassName;
            }
        }
        return defualtClassName;
    }

    const disableInputField = () =>{
        if(!original[resultIdProp]) { return true; }
        if((original[approvedByProp] || original['PrAPPROVEDBY']) && !procedureApprovals.includes(original['PrRef'])) { return true; }
        return false;
    }

    const onChange = e => {
        setValue(e.target.value)
    }
    
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateTableData(index, id, value)
    }

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return id.includes('WEBRESULT') ? 
    <div className="group flex relative justify-center">
        <input 
            className={buildClassName()} 
            value={value} 
            onChange={onChange} 
            onBlur={onBlur} 
            onMouseEnter={() => setShowTooltip(true && value)} 
            onMouseLeave={() => setShowTooltip(false)} 
            disabled={disableInputField()}
            ref={inputFieldRef}/>
        {showTooltip && <div className={`min-w-110 group-hover:opacity-100 transition-opacity bg-gray-800 px-1 py-1 text-xs text-gray-100 rounded-md absolute right-1/2 ${inputFieldRef.current && inputFieldRef.current.getBoundingClientRect().top > height*2/3 ? 'bottom-2' : 'top-2'}
            -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto text-left z-10`} >{buildTooltipText()}</div>}
    </div>
    : value;
}

const defaultColumn = {
    Filter: DefaultColumnFilter,
    Cell: EditableCell,
}

const ReactTable = ({ columns, data, getRegResultList, setShowResult, selectedRow, setSelectedRow, updateTableData, skipPageReset, enteredTestResults, setEnteredTestResults,procedureApprovals,selectedApprovalTests,setSelectedApprovalTests,isUnderApproval, setIsUnderApproval, approvalType,setApprovalType}) => {

    const [sortColumnName, setSortColumnName] = useState(null);
    const [sortAsc, setSortAsc] = useState(null);
    
    const { height, width } = useWindowDimensions();
    let trHeight = 53;
    let nonTrHeight = 329;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        loading,
        setFilter,

        //page,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        // gotoPage,
        // nextPage,
        // previousPage,
        // setPageSize,
        state
    } = useTable({
        columns,
        data,
        initialState: {
            //hiddenColumns:['RESULT_ID'],
            //pageIndex: 0
        },
        defaultColumn,
        autoResetPage: !skipPageReset,
        //filterTypes
        updateTableData,
        procedureApprovals,
        selectedRow,
        selectedApprovalTests,
        isUnderApproval,
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        //usePagination,
    );

    const changeSortingColumn = (column) => {
        
        if(!column.canSort) { return; }

        if(column.Header === sortColumnName) {
            if(sortAsc === true) { 
                setSortAsc(false);
                return;
            }
            if(sortAsc === false) { 
                setSortAsc(null);
                return;
            }
            if(sortAsc === null) { 
                setSortAsc(true);
                return;
            }
        }
        
        setSortColumnName(column.Header);
        setSortAsc(true);
    }

    const selectApprovalTestsByRow = (cell) => {
        if(!isUnderApproval || !procedureApprovals.includes(cell.row.original.PrRef) ) { return; }
        if(approvalType=='Procedures' && cell && cell.row && cell.row.original) {
            let tempApprovalTests = [...selectedApprovalTests];
            data.filter(d => d.PrRef === cell.row.original.PrRef).forEach((value,index)=>{
                Object.keys(value).forEach((key,i)=>{
                    if(key.includes('RESULT_ID')) {
                        let resultProps = key.replace('RESULT_ID', 'WEBRESULT');
                        if(value[resultProps] && value[key] && !tempApprovalTests.includes(value[key])) {
                            tempApprovalTests.push(value[key]);
                        }
                    }
                });
            });
            
            setSelectedApprovalTests(tempApprovalTests);
            return;
        }

        if(approvalType=='Results'&& cell.column.id && cell.column.id.includes('WEBRESULT')) {
            if(!cell.row.original[cell.column.id]) { return; }
            let tempApprovalTests = [...selectedApprovalTests];
            let resultIdColumnIndex = cell.column.id.replace('WEBRESULT', 'RESULT_ID');
            
            if(!tempApprovalTests.includes(cell.row.original[resultIdColumnIndex])) {
                tempApprovalTests.push(cell.row.original[resultIdColumnIndex]);
            }
            setSelectedApprovalTests(tempApprovalTests);
            return;
        }

        if(approvalType=='Results' && cell && cell.row && cell.row.original) {
            let tempApprovalTests = [...selectedApprovalTests];

            Object.keys(cell.row.original).forEach((key,i)=>{
                if(key.includes('RESULT_ID')) {
                    let resultProps = key.replace('RESULT_ID', 'WEBRESULT');
                    if(cell.row.original[resultProps] && cell.row.original[key] && !tempApprovalTests.includes(cell.row.original[key])) {
                        tempApprovalTests.push(cell.row.original[key]);
                    }
                }
            });
            setSelectedApprovalTests(tempApprovalTests);
            return;
        }
    }

    const selectApprovalTestsByColumn = (col) => {
        if(!isUnderApproval || approvalType!=='Results' || !col.id.includes('WEBRESULT')) { return; }

        if(data && data.length > 0) {
            let resultIdColumnIndex = col.id.replace('WEBRESULT', 'RESULT_ID');
            let tempApprovalTests = [...selectedApprovalTests];

            data.forEach((value,index)=>{
                if(value[col.id] && procedureApprovals.includes(value.PrRef)) {
                    if(!tempApprovalTests.includes(value[resultIdColumnIndex]) && !isNaN(value[resultIdColumnIndex])) {
                        tempApprovalTests.push(value[resultIdColumnIndex]);
                    }
                    
                }
            });

            setSelectedApprovalTests(tempApprovalTests);
        }
    }

    // useEffect(() => {
    //     Math.floor((height - nonTrHeight)/trHeight) > 1 ? setPageSize(Math.floor((height - nonTrHeight)/trHeight)) : setPageSize(1);
    // },[height]);

    return (
        <>
            <div className="-mb-2 flex p-4 items-center justify-center bg-gray-100 rounded-tr-lg rounded-tl-lg">
                <div className="px-4 border-r">Reg No.: { selectedRow.URegRef } </div>
                <div className="px-4 border-r max-w-511 truncate">Description: { selectedRow.JobDescrip } </div>
                <div className="px-4 border-r">Client: { selectedRow.Client } </div>
                <div className="px-4 border-r">Status: { selectedRow.StatCd } </div>
                <div className="px-4">Due Date: { selectedRow.DueDate } </div>
                <GlobalFilter 
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                <RefreshIcon getRegResultList={getRegResultList} selectedRegTID={selectedRow.RegTID}  setSortColumnName={setSortColumnName} setSortAsc={setSortAsc} setEnteredTestResults = {setEnteredTestResults}/>
            </div>
            <div className="h-4/5 overflow-y-auto bg-white">
            <table className="w-full divide-y divide-gray-200" {...getTableProps()}>
                <thead className="bg-gray-100">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th 
                                    className="p-2 px-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider" 
                                    key={column.Header} 
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                    <div 
                                        className="flex items-center justify-center"
                                        onClick={() => { changeSortingColumn(column); }}
                                    >
                                        <div className={`mr-1.5 ${isUnderApproval ? 'cursor-pointer' : ''}`}
                                            onClick={()=>selectApprovalTestsByColumn(column)}
                                        >
                                            {column.render('Header')}</div>
                                        {!column.disableSortBy && <SortingIcon columnName={column.Header} currentColumnName={sortColumnName} sortAsc={sortAsc} />}
                                        {column.canFilter ? column.render('Filter') : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className="bg-white divide-y divide-gray-200" {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr className="hover:bg-gray-100" {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                            <td className={`${!isNaN(cell.column.Header) ? "" : "py-2 px-3"} max-w-xs justify-center`} key={ i } {...cell.getCellProps()}>
                                                <div className={`w-fit whitespace-nowrap text-sm text-center text-gray-900 ${isUnderApproval ? 'cursor-pointer' : ''}`}
                                                    onClick={()=> selectApprovalTestsByRow(cell)}
                                                >
                                                    {cell.render('Cell')}
                                                </div>
                                            </td>
                                            )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            </div>
            <ReactTableFooter 
                setShowResult = {setShowResult}
                setSelectedRow = {setSelectedRow}
                enteredTestResults = {enteredTestResults}
                setEnteredTestResults = {setEnteredTestResults}
                canApprove={data.some(d => procedureApprovals.includes(d.PrRef))}
                selectedApprovalTests={selectedApprovalTests}
                setSelectedApprovalTests={setSelectedApprovalTests}
                setIsUnderApproval={setIsUnderApproval}
                approvalType={approvalType}
                setApprovalType={setApprovalType}
            />
        </>
    );
};

const RegistrationResultTable = ({regResults, setRegResults, procedureApprovals, getRegResultList, setShowResult, selectedRow, setSelectedRow}) => {

    const [enteredTestResults, setEnteredTestResults] = useState([]);
    const [selectedApprovalTests, setSelectedApprovalTests] = useState([]);
    const [isUnderApproval, setIsUnderApproval] = useState(false);
    const [approvalType, setApprovalType] = useState(null);

    const generateDynamicColumns = () => {
        let col = [
        {
            Header: 'Procedure',
            accessor: 'PrDescrip'
        },
        {
            Header: 'Test',
            accessor: 'TeDescrip'
        },
        {
            Header: 'Method',
            accessor: 'MDescrip',
            disableSortBy: true
        },
        {
            Header: 'Unit',
            accessor: 'Units',
            disableSortBy: true
        },
        {
            Header: 'LOQ',
            accessor: 'LMin',
            disableSortBy: true
        }];
        for (let i = 0; i < selectedRow.NoSamp; i++) {
            col.push({
                Header: i+1,
                accessor: 'Sample'+(i+1).toString()+'WEBRESULT',
                disableSortBy: true
            });
        }
        return col;
    }

    const [skipPageReset, setSkipPageReset] = useState(false)

    const updateTableData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true)
        setRegResults(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    let resultIdColumnIndex = columnId.replace('WEBRESULT','RESULT_ID');
                    let objIndex = enteredTestResults.findIndex(o => o.resultId == row[resultIdColumnIndex]);
                    let tempArray = enteredTestResults;
                    if(objIndex !== -1) {
                        tempArray[objIndex].result = value;
                        setEnteredTestResults(tempArray);
                    }
                    else {
                        if(value != old[rowIndex][columnId]) {
                            tempArray.push({resultId:row[resultIdColumnIndex], result:value})
                            setEnteredTestResults(tempArray);
                        }
                    }

                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }

    useEffect(() => {
        setSkipPageReset(false)
      }, [regResults])

    return (
        <>
        {regResults &&
            <div className="w-screen h-screen fixed left-0 top-0 bg-gray-800 bg-opacity-80 z-20 flex items-center">
                <div className="w-11/12 h-4/5 mx-auto">
                    <ReactTable 
                        columns={generateDynamicColumns()}  
                        data={regResults} 
                        getRegResultList={getRegResultList} 
                        setShowResult={setShowResult} 
                        selectedRow={selectedRow} 
                        setSelectedRow={setSelectedRow} 
                        updateTableData={updateTableData} 
                        skipPageReset={skipPageReset} 
                        enteredTestResults={enteredTestResults} 
                        setEnteredTestResults={setEnteredTestResults}
                        procedureApprovals={procedureApprovals}
                        selectedApprovalTests={selectedApprovalTests}
                        setSelectedApprovalTests={setSelectedApprovalTests}
                        isUnderApproval={isUnderApproval}
                        setIsUnderApproval={setIsUnderApproval}
                        approvalType={approvalType}
                        setApprovalType={setApprovalType}
                    />
                </div>
            </div>
        }
        </>
    );
}

export default RegistrationResultTable;



