import React, { useState, useEffect, useRef } from 'react';
import api from '../../config/Api';
import { Document, Page, pdfjs } from 'react-pdf';
import { getSpinnerIcon, getSolidCheckCircleIcon, getSolidXIcon, getChevronUpIcon } from '../../helper/SvgHelper';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
//pdf.js version pdfjs-dist-2.10.377 in public folder pdf.worker.min.js

const EmailReportCatalog = ({selectedRow, setSelectedRow, setShowEmailReportCatalog}) => {

    const [receiverEmail, setReceiverEmail] = useState(null);
    const [ccEmailList, setCcEmailList] = useState([]);
    const [subjectStr, setSubjectStr] = useState(null);
    const [bodyMsg, setBodyMsg] = useState(null);
    const [attachmentArry, setAttachmentArry] = useState([]);
    const [reportTemplate, setReportTemplate] = useState('');
    const [rptVersionNo, setRptVersionNo] = useState('[R00]');
    const [amendComment, setAmendComment] = useState('');

    const [loading, setLoading] = useState(false);
    const [responseStatus, setResponseStatus] = useState(null);
    const [responseMsg, setResponseMsg] = useState(null);
    const [showResponseMsg, setShowResponseMsg] = useState(false);

    const buttonGroupOptionRef = useRef(null);
    const [showButtonGroupOptions, setShowButtonGroupOptions] = useState(false);

    const customOptions = ['PDF', 'XLSX'];

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const selectButtonGroupOption = async (option) => {
        const a = document.createElement('a');
        const fileName = option === 'PDF' ? selectedRow.URegRef + '-' +rptVersionNo : selectedRow.URegRef;
        a.download = fileName + '.' + option.toLowerCase();
        a.href = '';
        if(option === 'PDF') {
            a.href = attachmentArry[0];
        }
        else {
            if(attachmentArry.length > 1) {
                a.href = attachmentArry[1];
            }
            else {
                await api.get('/lims/registration-csv-report', { params: { regTid: selectedRow.RegTID } })
                .then(res => {
                    if(res.data.success) { 
                        a.href = res.data.data;
                        attachmentArry.push(res.data.data);
                    }
                })
                .catch(err => {
                    console.log(err)
                });
            }
        }
        a.click();
        a.remove();
        setShowButtonGroupOptions(false);
    }

    const clearAllStates = () =>{
        setLoading(false);

        setReceiverEmail(null);
        setCcEmailList([]);
        setSubjectStr(null);
        setBodyMsg(null);
        setAttachmentArry([]);
        setReportTemplate('');

        setNumPages(null);
        setPageNumber(1);
        
        setSelectedRow(null);
        setShowEmailReportCatalog(false);
    }

    const getRegReportDetailsByRegTid = async () => {
        if(!selectedRow || !selectedRow.RegTID) { return; }
        setLoading(true);
        await api.get('/lims/registration-report-details', { params: { regTid: selectedRow.RegTID } })
        .then(res => {
            if(res.data.success) { 
                setReceiverEmail(res.data.data.receiver);
                setCcEmailList(res.data.data.ccList);
                setSubjectStr(res.data.data.subject);
                setBodyMsg(res.data.data.body);
                setAttachmentArry(res.data.data.attachments);
            }else {
                setReceiverEmail(null);
                setCcEmailList([]);
                setSubjectStr(null);
                setBodyMsg(null);
                setAttachmentArry([]);
            };
        })
        .catch(err => {
            console.log(err)
        });
        setLoading(false);
    }

    const getRegReportDetailsByRegTidReportTemplate = async () => {
        if(!selectedRow || !selectedRow.RegTID || !reportTemplate) { return; }
        setLoading(true);
        await api.get('/lims/registration-report-details', { params: { regTid:selectedRow.RegTID, reportTemplate:reportTemplate, amendComment:amendComment, amendVersion:rptVersionNo} })
        .then(res => {
            if(res.data.success) { 
                setReceiverEmail(res.data.data.receiver);
                setCcEmailList(res.data.data.ccList);
                setSubjectStr(res.data.data.subject);
                setBodyMsg(res.data.data.body);
                setAttachmentArry(res.data.data.attachments);
            }else {
                setReceiverEmail(null);
                setCcEmailList([]);
                setSubjectStr(null);
                setBodyMsg(null);
                setAttachmentArry([]);
            };
        })
        .catch(err => {
            console.log(err)
        });
        setLoading(false);
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const PdfNavigatior = () => {
        return (
            <div className="flex mt-1 -mb-1 ml-14">
                <p>
                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </p>
                <div className="ml-4 -mt-1.5 mb-1.5">
                    <button
                        className={`${pageNumber <= 1 ? 'bg-gray-600 hover:bg-gray-700' : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'} px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3`}
                        type="button"
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                    >
                        Previous
                    </button>
                    <button
                        className={`${pageNumber >= numPages ? 'bg-gray-600 hover:bg-gray-700' : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'} px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3`}
                        type="button"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                    >
                        Next
                    </button>
                </div>
            </div>
        )
    }

    const updateRegKeyEmailDate = async () => {
        if(!selectedRow || !selectedRow.RegTID) { return; }
        setLoading(true);
        await api.put('/lims/manual-send-report', { regTid:selectedRow.RegTID })
        .then(res => {
            if(res.data.success) { 
            };
        })
        .catch(err => {
            console.log(err)
        });
        setLoading(false);
    }

    const EmailComponent = () => {
       
        const handleOpenEmail = async () => {
            let email = receiverEmail;
            let ccList = ccEmailList;
            let subject = subjectStr;
            let body = bodyMsg;
            let attachments = attachmentArry;
        
            let ccString = ccList.join(',');
            let emailUrl = `mailto:${email}?cc=${encodeURIComponent(ccString)}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

            // This is not allowed in Outlook
            // if (attachments.length > 0) {
            //   emailUrl += `&attachments=${attachments.join(',')}`;
            // }
            
            window.location.href = emailUrl;
        };
        
        return (
            <button 
                type="button" 
                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
                disabled={loading ? true : false}
                onClick={() => {handleOpenEmail(); updateRegKeyEmailDate();}}
            >
            { loading ? <div className="px-2">{getSpinnerIcon()}</div> : 'Email' }
            </button>
        );
    };



    useEffect(() => {
        getRegReportDetailsByRegTid();
        const handleClickOutside = (event) => {
            if(buttonGroupOptionRef.current && !buttonGroupOptionRef.current.contains(event.target)) {
                setShowButtonGroupOptions(false);
            }
        }
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    useEffect(() => {
        getRegReportDetailsByRegTidReportTemplate();
    }, [reportTemplate]);

    return (
        <div className="w-screen h-screen fixed left-0 top-0 bg-gray-800 bg-opacity-80 z-20 flex items-center">
            <div className="w-3/5 mx-auto">
                <div className="py-5 px-16 flex justify-between bg-gray-100 rounded-t-lg">
                    <div className="text-gray-900 text-left text-sm w-1/3 mt-3 -mb-3">
                        Reg No.: {selectedRow.URegRef} {selectedRow.StatCd}
                    </div>
                    <div className="text-gray-900 text-center font-semibold w-1/3">
                        Report Preview
                    </div>
                    <div className="flex text-gray-900 text-right text-sm w-1/3 mt-1 -mb-3">
                        <label className="ml-20 mt-1">Template:</label>
                        <select className="ml-2 mb-1 py-1 px-4 block shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                            value={reportTemplate || ''}
                            onChange={e => { setReportTemplate(e.target.value); }}>
                            <option value=''></option>
                            <option value="IANZ_REPORT">IANZ</option>
                            <option value="IANZ_AMENDED">IANZ Amended</option>
                            <option value="CNAS_REPORT">CNAS</option>
                            <option value="CNAS_AMENDED">CNAS Amended</option>
                            <option value="Gen_REPORT">General</option>
                            <option value="Gen_AMENDED">General Amended</option>
                            <option value="Gen_CHN_RPT">General-CHN</option>
                            <option value="Gen_CHN_AMD">General-CHN Amended</option>
                        </select>
                    </div>
                </div>
                <div className="flex shadow bg-white">
                    <div className="border-l flex-grow">
                        <div className="my-4 max-h-630 h-630 overflow-y-auto overflow-x-auto">
                            {/* Report Preview */}
                            { attachmentArry && attachmentArry.length > 0 &&  
                                <>
                                <div className="flex justify-center my-2">
                                    <div className="w-80"></div>
                                    <div className="w-56"></div>
                                    <PdfNavigatior /> 
                                </div>
                                <div className="flex justify-center px-2">
                                    <Document style={{ fontFamily: 'ms-black', fontWeight: 'bold', fontSize: 15 }} className="border border-gray-200 max-w-863 w-863" file={attachmentArry[0]} onLoadSuccess={(e) => onDocumentLoadSuccess(e)}>
                                        <Page pageNumber={pageNumber} width={850} />
                                    </Document>
                                </div>
                                <div className="flex justify-center my-2">
                                    <div className="w-80"></div>
                                    <div className="w-56"></div>
                                    <PdfNavigatior /> 
                                </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex rounded-b-lg shadow bg-gray-100 justify-between">
                    <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <button onClick={()=>{ clearAllStates(); }} type="button" className="items-center px-3 py-2.5 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Close
                        </button>
                    </div>
                    {(reportTemplate.includes('AMENDED') || reportTemplate.includes('AMD')) &&
                    <div className="flex h-9 mt-3">
                        <label className="pt-1 font-bold">Comment:</label>
                        <input 
                            className="w-80 px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none text-sm"
                            value={amendComment || ''}
                            onChange={e => setAmendComment(e.target.value)}
                        />
                        <input 
                            className="border px-3 py-2 w-24 text-center border-gray-300 bg-white text-sm font-medium text-gray-700 focus:outline-none hover:bg-gray-50 cursor-pointer"
                            placeholder="Verion No."
                            value={rptVersionNo || ''}
                            onChange={e => setRptVersionNo(e.target.value)}
                        />
                        <button disabled={loading} onClick={() => getRegReportDetailsByRegTidReportTemplate()} type="button" className={`${loading ? 'px-4' : 'px-3'} py-2 border border-transparent text-sm leading-4 font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}>
                            {loading ? <div className="px-1.5">{getSpinnerIcon()}</div> : "Apply"}
                        </button>
                    </div>}
                    <div className="px-6 py-3">
                        <div ref={ buttonGroupOptionRef } className="relative inline-block text-left mx-5">
                            <div className="inline-flex rounded-md shadow-sm">
                                <button
                                    type="button"
                                    className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-800 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
                                    onClick={(e) => {setShowButtonGroupOptions(!showButtonGroupOptions); e.stopPropagation(); }}
                                >
                                    Download
                                </button>
                                <div className="relative -ml-px block">
                                    <button 
                                        className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
                                        onClick={(e) => {setShowButtonGroupOptions(!showButtonGroupOptions); e.stopPropagation(); }}
                                        >
                                        <span className="sr-only">Open options</span>
                                        <div>{getChevronUpIcon()}</div>
                                    </button>
                                </div>
                            </div>
                            {showButtonGroupOptions && 
                            <div className="absolute top-0 right-0 left-0 -mt-24 p-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                {customOptions.map((option, i) => (
                                <div className="py-1 hover:bg-blue-100 rounded-md" key={i}>
                                    <div 
                                        className={'text-md text-gray-900 cursor-pointer px-2 py-1'}
                                        onClick={()=>{selectButtonGroupOption(option)}}
                                    >
                                        {option}
                                    </div>
                                </div>))}
                            </div>}
                        </div>
                        
                        <EmailComponent />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailReportCatalog;