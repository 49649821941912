const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const NzDateStringToIsoDate = (nzDate) => {
    // To overcome due date is null
    if(!nzDate) { return new Date().setDate(new Date().getDate()-1); }
    let dateArray = nzDate.split('-');
    return new Date(dateArray[2], dateArray[1] - 1, dateArray[0])
}

export const IsoDateTimeToIsoDate = (isoDate) => {
    isoDate = new Date(isoDate);
    return new Date(isoDate.getFullYear(), isoDate.getMonth(), isoDate.getDate());
}

export const IsoDateToNzDateWithMonthNameStr = (isoDate) => {
    return isoDate.getDate() + ' ' + monthNames[isoDate.getMonth()] + ' ' + isoDate.getFullYear();
}

export const DayDifference = (startDate, endDate) => {
    let diffTime = endDate - startDate;
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export default NzDateStringToIsoDate;